<template>
  <section v-html="bodyText"></section>
</template>

<script>
import { httpMixin } from "@/mixins/httpMixin.js";

export default {
  mixins: [httpMixin],

  props: {
    data: Object
  },

  computed: {
    bodyText: function() {
      return this.sanitise(this.data.body[0].value).replace(/h2/g, "h3");
    }
  }
};
</script>

<style scoped>
section {
  margin: 20px 0;
  text-align: left;
  max-width: 940px;
  overflow-wrap: break-word;
  z-index: 20;
}

@media screen and (min-width: 768px) {
  section {
    margin: 120px 60px;
  }
}
</style>
